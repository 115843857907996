import { Popover, PopoverContentProps, PopoverProps, YGroup } from 'tamagui'
import { Button, ButtonProps } from '../button'
import { ChevronDown, ChevronUp } from '@tamagui/lucide-icons'
import { SmartLink } from '../smart-link'
import { shadowStandard } from '../../styles'

const DropdownButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      justifyContent="flex-start"
      textAlign="left"
      plain
      size="$3"
      px="$2.5"
      {...props}
    >
      {children}
    </Button>
  )
}

export interface DropdownItemProps {
  href: string
  label: string
}

interface DropdownMenuProps {
  initialItem: DropdownItemProps
  items: DropdownItemProps[]
  popoverProps?: PopoverProps
  contentProps?: PopoverContentProps
  itemProps?: ButtonProps
  triggerProps?: ButtonProps
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const DropdownMenu = (props: DropdownMenuProps) => {
  return (
    <Popover
      open={props.open}
      onOpenChange={props.onOpenChange}
      placement="bottom-start"
      strategy="absolute"
      {...props.popoverProps}
    >
      <Popover.Anchor>
        <DropdownButton
          onPress={() => props.onOpenChange(true)}
          iconAfter={<ChevronDown size={18} />}
          zIndex="$1"
          {...props.triggerProps}
        >
          {props.initialItem.label}
        </DropdownButton>
      </Popover.Anchor>
      <Popover.Content p="$0" {...shadowStandard} {...props.contentProps}>
        <YGroup ai="flex-start" bg="$white">
          {props.items.map((item) => (
            <SmartLink
              Component={DropdownButton}
              onPress={() => props.onOpenChange(false)}
              key={item.href}
              href={item.href}
              w="100%"
              {...(props.initialItem.href === item.href && {
                iconAfter: <ChevronUp size={18} />,
              })}
              {...props.itemProps}
            >
              {item.label}
            </SmartLink>
          ))}
        </YGroup>
      </Popover.Content>
    </Popover>
  )
}
