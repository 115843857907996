import { SizableText, SmartLink, XStack, colors, styled } from '@my/ui'
import { ArrowDownAZ } from '@tamagui/lucide-icons/dist/esm/icons/arrow-down-a-z' //bug with library so have to import this icon like this
import {
  Activity,
  Flame,
  HeartPulse,
  Home,
  MonitorSmartphone,
  PiggyBank,
  Pizza,
  Plane,
  Shirt,
  Smartphone,
  Tornado,
} from '@tamagui/lucide-icons'
import { CategoryTabsBlock } from './category-tabs.block'

const defaultIcons = [
  { label: 'Flame', Icon: Flame },
  { label: 'Shirt', Icon: Shirt },
  { label: 'Pizza', Icon: Pizza },
  { label: 'Plane', Icon: Plane },
  { label: 'Home', Icon: Home },
  { label: 'Tornado', Icon: Tornado },
  { label: 'Heart', Icon: HeartPulse },
  { label: 'Tech', Icon: MonitorSmartphone },
  { label: 'Activity', Icon: Activity },
  { label: 'Bank', Icon: PiggyBank },
  { label: 'AZ', Icon: ArrowDownAZ },
  { label: 'Smartphone', Icon: Smartphone },
]

const TabButton = styled(XStack, {
  position: 'relative',
  ai: 'center',
  borderRadius: '$3',
  py: '$2',
  px: '$3',
  width: 'auto',
  bg: '$white',
  gap: '$2',
  borderWidth: 1,
  borderColor: '$grey3',
  hoverStyle: {
    opacity: 0.9,
    bg: '$primary4',
  },
  focusStyle: {
    opacity: 0.9,
    bg: '$primary4',
  },
  pressStyle: {
    opacity: 0.6,
    bg: '$primary4',
  },
  variants: {
    active: {
      true: {
        opacity: 1,
        bg: '$primary',
        hoverStyle: {
          bg: '$primary4',
        },
        pressStyle: {
          bg: '$primary5',
        },
        focusStyle: {
          bg: '$primary4',
        },
      },
    },
  } as const,
})

export type CategoryTabsItemProps = CategoryTabsBlock['categories'][0] & {
  href: string
  selected?: boolean
}

export const CategoryTabsItem = (props: CategoryTabsItemProps) => {
  const iconIndex = defaultIcons.findIndex((item) => item.label === props.icon)

  const IconComponent =
    iconIndex >= 0 ? (defaultIcons[iconIndex]?.Icon as React.NamedExoticComponent) : Flame

  return (
    <XStack>
      <SmartLink Component={TabButton} href={props.href} active={props.selected}>
        <IconComponent size={24} color={props.selected ? colors.black : colors.grey8} />

        <SizableText size="$3" fow="600" color={props.selected ? colors.black : colors.grey8}>
          {props.name}
        </SizableText>
      </SmartLink>
    </XStack>
  )
}
