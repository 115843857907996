import { Stack, YStack, StackProps, isWeb, shadowStandard } from '@my/ui'
import { NAV_HEIGHT, NAV_HEIGHT_SM } from './navigation.web'

export const SECONDARY_NAV_HEIGHT = 60

// TODO:
// Make a component for CategoryTabs, GroupNavCarousel out if this.
// for now it's just a wrapper

export interface SecondaryNavigationProps extends StackProps {
  children?: React.ReactNode
  spacerProps?: StackProps
}

export const SecondaryNavigation = ({
  children,
  spacerProps,
  ...props
}: SecondaryNavigationProps) => {
  return (
    <>
      <YStack
        w="100%"
        bc="$white"
        jc="center"
        top={NAV_HEIGHT}
        h={SECONDARY_NAV_HEIGHT}
        $gtSm={{ top: NAV_HEIGHT_SM }}
        zIndex="$5"
        pos={isWeb ? ('fixed' as 'absolute') : 'relative'}
        {...shadowStandard}
        {...props}
      >
        {children}
      </YStack>
      {isWeb && <Stack opacity={0} h={SECONDARY_NAV_HEIGHT} {...spacerProps} />}
    </>
  )
}
